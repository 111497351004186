import { lazy } from 'react';
import { permissions } from '../services/roles';

export const publicOpenRoutes = [
  {
    path: '/live-preview/authenticate',
    Component: lazy(() => import('../views/auth/livePreviewAuth')),
    exact: true,
  },
];

export const publicRoutes = [
  {
    path: '/auth/login',
    Component: lazy(() => import('../views/auth/login')),
    exact: true,
  },
  {
    path: '/auth/register/account',
    Component: lazy(() => import('../views/auth/register')),
    exact: true,
  },
  {
    path: '/auth/password/forgot',
    Component: lazy(() => import('../views/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/password/reset',
    Component: lazy(() => import('../views/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/password/first-login',
    Component: lazy(() => import('../views/auth/first-login')),
    exact: true,
  },
];

export const privateRoutes = [
  {
    path: '/auth/register/verify',
    Component: lazy(() => import('../views/auth/register/verify')),
    exact: true,
  },
  {
    path: '/auth/register/company',
    Component: lazy(() => import('../views/auth/register/company/company')),
    exact: true,
  },
];

export const verifiedOnlyRoutes = [
  {
    path: '/settings',
    Component: lazy(() => import('../views/settings')),
    exact: false,
    roles: permissions.settings,
  },
];

export const activeSubscriptionRoutes = [
  {
    path: '/management',
    Component: lazy(() => import('../views/management')),
    exact: false,
    roles: permissions.management,
  },
  {
    path: '/policy-manager/:branchId/add-policy-manger',
    Component: lazy(
      () => import('../views/management/policy-manager/add-policy-manger'),
    ),
    exact: true,
    roles: permissions.policyAddEditDelete,
  },
  {
    path: '/policy-manager/:branchId/edit-policy-manger/:policyId',
    Component: lazy(
      () => import('../views/management/policy-manager/edit-policy-manger'),
    ),
    exact: true,
    roles: permissions.policyAddEditDelete,
  },
  {
    path: '/analytics',
    Component: lazy(() => import('../views/analytics')),
    exact: false,
    roles: permissions.analytics,
  },
  {
    path: '/marketing',
    Component: lazy(() => import('../views/marketing')),
    exact: false,
    roles: permissions.marketing,
  },
  {
    path: '/door-access/:buildingId/floors',
    Component: lazy(() => import('../views/door-access/floors')),
    exact: true,
    roles: permissions.doorAccess,
  },
  {
    path: '/door-access/:floorId/doors',
    Component: lazy(() => import('../views/door-access/doors')),
    exact: true,
    roles: permissions.doorAccess,
  },
  {
    path: '/door-access/:floorId/doors/:doorId',
    Component: lazy(() => import('../views/door-access/door-details')),
    exact: false,
    roles: permissions.doorAccess,
  },
  {
    path: '/door-access',
    Component: lazy(() => import('../views/door-access/door-access')),
    exact: false,
    roles: permissions.doorAccess,
  },
];

export const expiredSubscriptionRoutes = [
  {
    path: '/expired-subscription',
    Component: lazy(() => import('../views/expired-subscription')),
    exact: true,
    roles: permissions.all,
  },
];
export const pendingActivationRoutes = [
  {
    path: '/pending-activation',
    Component: lazy(() => import('../views/pending-activation')),
    exact: true,
    roles: permissions.all,
  },
];

export const analyticsRoutes = [
  {
    path: '/analytics/footfall/acquisitions',
    Component: lazy(
      () => import('../views/analytics/footfall/acquisitions/index'),
    ),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/footfall/time',
    Component: lazy(() => import('../views/analytics/footfall/time&occupancy')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/loyalty/age&gender',
    Component: lazy(() => import('../views/analytics/loyalty/age&gender')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/loyalty/customers-loyalty',
    Component: lazy(() => import('../views/analytics/loyalty/loyalty')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/loyalty/customers',
    Component: lazy(() => import('../views/analytics/loyalty/customers')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/internet/users&devices',
    Component: lazy(() => import('../views/analytics/internet/users&devices')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/internet/consumption',
    Component: lazy(() => import('../views/analytics/internet/consumption')),
    exact: true,
    roles: permissions.analytics,
  },
  {
    path: '/analytics/settings/exclusion',
    Component: lazy(() => import('../views/analytics/settings/exclusion')),
    exact: true,
    roles: permissions.analytics,
  },
];

export const marketingRoutes = [
  {
    path: '/marketing/ads/login-page/:status',
    Component: lazy(
      () => import('../views/marketing/login-page-ads/ads/index'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/ads/login-page/:id/:view',
    Component: lazy(
      () => import('../views/marketing/login-page-ads/ad-details/index'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/ads/login-page',
    Component: lazy(
      () => import('../views/marketing/login-page-ads/ads/index'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/survey',
    Component: lazy(
      () => import('../views/marketing/surveys/surveys-list-page/index'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/campaign',
    Component: lazy(
      () => import('../views/marketing/campaigns/campaigns-list-page'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/survey/:surveyId/details',
    Component: lazy(() => import('../views/marketing/surveys/surveys-details')),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/campaign/:campaignId/details',
    Component: lazy(
      () => import('../views/marketing/campaigns/campaign-details'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/survey/:status',
    Component: lazy(
      () => import('../views/marketing/surveys/surveys-list-page/index'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/campaign/:status',
    Component: lazy(
      () => import('../views/marketing/campaigns/campaigns-list-page'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/add-survey',
    Component: lazy(
      () => import('../views/marketing/surveys/create-edit-survey'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/surveys/:id/edit',
    Component: lazy(
      () => import('../views/marketing/surveys/create-edit-survey'),
    ),
    exact: true,
    roles: permissions.marketing,
  },

  {
    path: '/marketing/add-campaign',
    Component: lazy(
      () => import('../views/marketing/campaigns/create-edit-campaign'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/redeem-promo-code',
    Component: lazy(
      () => import('../views/marketing/promo-codes/redeem-promo-code'),
    ),
    exact: true,
    roles: permissions.marketing,
  },

  {
    path: '/marketing/campaigns/:id/edit',
    Component: lazy(
      () => import('../views/marketing/campaigns/create-edit-campaign'),
    ),
    exact: true,
    roles: permissions.marketing,
  },
  {
    path: '/marketing/promo-code',
    Component: lazy(() => import('../views/marketing/promo-codes')),
    exact: true,
    roles: permissions.marketing,
  },
];

export const managmentRoutes = [
  // {
  //   path: '/management/lines-monitoring',
  //   Component: lazy(() => import('../views/management/lines-monitoring/V1/index')),
  //   exact: true,
  //   roles: permissions.lines,
  // },
  {
    path: '/management/lines-monitoring',
    Component: lazy(
      () => import('../views/management/lines-monitoring/V2/lines/index'),
    ),
    exact: true,
    roles: permissions.lines,
  },
  {
    path: '/management/lines-monitoringV2/line-details/:branchId/:portName/:lineId',
    Component: lazy(
      () =>
        import('../views/management/lines-monitoring/V2/line-details/index'),
    ),
    exact: true,
    roles: permissions.lines,
  },
  {
    path: '/management/policy-manager',
    Component: lazy(() => import('../views/management/policy-manager')),
    exact: true,
    roles: permissions.policy,
  },
  {
    path: '/management/policy-manager/:branchId/:policyId',
    Component: lazy(
      () => import('../views/management/policy-manager/policy-details'),
    ),
    exact: false,
    roles: permissions.policyAddEditDelete,
  },
  {
    path: '/management/policy-manager/:policyId/users',
    Component: lazy(
      () => import('../views/management/policy-manager/policy-users'),
    ),
    exact: true,
    roles: permissions.policyAddEditDelete,
  },

  {
    path: '/management/gateway',
    Component: lazy(() => import('../views/management/gateway')),
    exact: true,
    roles: permissions.gateway,
  },
  {
    path: '/management/traffic-manager',
    Component: lazy(() => import('../views/management/traffic-manager')),
    exact: true,
    roles: permissions.traffic,
  },
  {
    path: '/management/security-profiles',
    Component: lazy(() => import('../views/management/security')),
    exact: true,
    roles: permissions.applicationfiltration,
  },
  {
    path: '/management/security-profiles/:branchId/:securityProfileId',
    Component: lazy(
      () => import('../views/management/security/securityProfileDetails'),
    ),
    exact: false,
    roles: permissions.applicationfiltration,
  },
  {
    path: '/management/sessions',
    Component: lazy(() => import('../views/management/sessions')),
    exact: false,
    roles: permissions.sessions,
  },
  {
    path: '/management/sessions-v2',
    Component: lazy(() => import('../views/management/sessions-v2')),
    exact: false,
    roles: permissions.sessions,
  },
  {
    path: '/management/users',
    Component: lazy(() => import('../views/management/users')),
    exact: false,
    roles: permissions.users,
  },
  {
    path: '/management/devices',
    Component: lazy(() => import('../views/management/devices')),
    exact: false,
    roles: permissions.devices,
  },
  {
    path: '/management/login-page-builder',
    Component: lazy(() => import('../views/settings/login-page-builder')),
    exact: true,
    roles: permissions.loginPageBuilder,
  },
  {
    path: '/management/access-code',
    Component: lazy(() => import('../views/management/access-codes')),
    exact: true,
    roles: permissions.policy,
  },
];

export const settingsRoutes = [
  {
    path: '/settings/team-members',
    Component: lazy(() => import('../views/team-members/index')),
    exact: false,
    roles: permissions.staff,
  },
  {
    path: '/settings/billing',
    Component: lazy(() => import('../views/billing')),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: '/settings/billing/payment/:paymentType/:value',
    Component: lazy(() => import('../views/billing/payment')),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: '/settings/billing/add-winif-credit',
    Component: lazy(() => import('../views/billing/add-winif-credit')),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: '/settings/billing/transactions-history',
    Component: lazy(() => import('../views/billing/transactions-history')),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: '/settings/logger',
    Component: lazy(() => import('../views/settings/logger')),
    exact: false,
    roles: permissions.logger,
  },
  {
    path: '/settings/branches',
    Component: lazy(() => import('../views/settings/branches')),
    exact: true,
    roles: permissions.companyAndBranchesRead,
  },
  {
    path: '/settings/company-basic-info',
    Component: lazy(() => import('../views/settings/company-basic-info')),
    exact: true,
    roles: permissions.companyAndBranchesRead,
  },
  {
    path: '/settings/account/two-factor-auth',
    Component: lazy(() => import('../views/settings/security')),
    exact: true,
    roles: permissions.all,
  },
];
