/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import { useReminderAlertStyles } from './style';
import moment from 'moment';
import { AppBar } from '@mui/material';
import { useTheme } from '@mui/material';
import { companyDataState } from '../../../store/company-data';
import { useRecoilValue } from 'recoil';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';

const OutstandingPaymentReminder: React.FC = () => {
  const classes = useReminderAlertStyles();
  const theme = useTheme();
  const {
    isHasOutstandingPayment,
    company: { serviceStatus },
  } = useRecoilValue(companyDataState);

  const daysUntilServiceEnds = (): number => {
    const today = moment();
    const startOfCurrentMonth = moment().startOf('month');
    const twentiethOfCurrentMonth = startOfCurrentMonth.add(7, 'days');
    // banner appears from 1st to 20th so "today" will always be less than 20th
    const diffDays = twentiethOfCurrentMonth.diff(today, 'days');
    return diffDays;
  };

  const splitNumbers = (numbers: number): Array<string> => {
    const numberArr = Array.from(`${numbers}`.split(''));
    if (numberArr.length === 1) {
      // single digits 4 8
      return ['0', ...numberArr];
    } else {
      // double digits 12 15
      return numberArr;
    }
  };

  const renderBanner = (): boolean => {
    return !!(
      daysUntilServiceEnds() > 0 &&
      isHasOutstandingPayment &&
      serviceStatus !== 'disabled'
    );
  };

  return renderBanner() ? (
    <AppBar position='sticky' sx={{ zIndex: theme.zIndex.modal }}>
      <Alert className={classes.reminder} severity='info' icon={false}>
        You have only{' '}
        <span className={classes.days}>
          {splitNumbers(daysUntilServiceEnds())[0]}
        </span>
        <span className={classes.days}>
          {splitNumbers(daysUntilServiceEnds())[1]}
        </span>{' '}
        days left till the end of the service. Please proceed with paying your
        bill
        <Button
          className={classes.addButton}
          size={'medium'}
          variant='contained'
        >
          <RouterLink
            to='/settings/billing'
            style={{ textDecoration: 'none', color: '#222' }}
          >
            Pay bill
          </RouterLink>
        </Button>
      </Alert>
    </AppBar>
  ) : (
    <></>
  );
};

export default OutstandingPaymentReminder;
