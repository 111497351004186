const locales = {
  resetYourPassword: 'Reset your password',
  chooseNewPassword: 'Choose a new password. Make sure its strong!',
  newPassword: 'New password',
  aminimumOf10Characters: 'A minimum of 10 characters',
  atLeastOneNumber: 'At least one number',
  atLeastOneLowercaseLetter: 'At least one lowercase letter',
  atLeastOneUppercaseLetter: 'At least one uppercase letter',
  confirmPassword: 'Confirm password',
  reset: 'Reset',
  knowYourPasswordAlready: 'Know your password already?',
  loginInstead: 'Login instead',
  expiredLink: 'Oops... Looks like your link has expired.',
  clickToGetNewLink: 'Click below to get a new link.',
  getNewLink: 'Get a new link',
  goBackTo: 'Go back to',
  loginPage: 'Login page',
  hide: 'Hide',
  show: 'Show',
  shouldBeMatched: 'Password and confirm password should be matched!',
  hangTightLoading: 'Hang tight! We are resetting your password!',
  oops: 'OOPS..',
};

export default locales;
