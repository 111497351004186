import { atom } from 'recoil';
import { localstorageKeys } from '../services/local-storage-keys';

export const livePreviewState = atom({
  key: 'livePreview', // unique ID
  default: localStorage.getItem(localstorageKeys.isLivePreview),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((livePreview) => {
        if (livePreview) {
          localStorage.setItem(localstorageKeys.isLivePreview, livePreview);
        }
      });
    },
  ],
});
