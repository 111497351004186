import { makeStyles } from '@mui/styles';
export const useTopBarStyle = makeStyles(() => ({
  normal: {
    fontWeight: 500,
    '&:hover': {},
  },
  active: {
    position: 'relative',
    color: '#fff',
    // background: "#283448",
    fontWeight: 'bold',
    '&::after': {
      content: '""',
      width: '100%',
      height: '4px',
      backgroundColor: '#0076ff',
      left: 0,
      position: 'absolute',
      bottom: '-8px',
      borderRadius: '5px 5px 0 0',
    },
  },
}));
