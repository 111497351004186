/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState } from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  Badge,
  ClickAwayListener,
  IconButton,
  Box,
  Stack,
  Typography,
  Button,
  Avatar,
  CircularProgress,
  Grow,
  Alert,
  AlertTitle,
} from '@mui/material';

import { NotificationItem } from '../../../types/notifications';
import { useNotificationsQuery } from '../../../queries/notifications';

// Icons
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import acceptedIcon from '../../../assets/notifications/success.svg';
import rejectedIcon from '../../../assets/notifications/rejected.svg';
import outstandingIcon from '../../../assets/notifications/outstanding.svg';
import renewedIcon from '../../../assets/notifications/renewed.svg';
import notRenewedIcon from '../../../assets/notifications/not-renewed.svg';
import internetOnIcon from '../../../assets/notifications/internet-on.svg';
import internetOffIcon from '../../../assets/notifications/internet-off.svg';
import reminderIcon from '../../../assets/notifications/reminder.svg';
import notificationsFlag from '../../../assets/notifications-flag.svg';
import { useEffect } from 'react';
import { localstorageKeys } from '../../../services/local-storage-keys';
import { companyDataState } from '../../../store/company-data';
import { useRecoilValue } from 'recoil';

const getIcon = {
  bank_approved: acceptedIcon,
  bank_rejected: rejectedIcon,
  outstanding_cronjobs: outstandingIcon,
  payment_success: renewedIcon,
  payment_failure: notRenewedIcon,
  service_enabled: internetOnIcon,
  service_disabled: internetOffIcon,
  incoming_payment: reminderIcon,
};

export const Notifications: FC = () => {
  const [open, setOpen] = useState(false);
  const oldReadNotifications = localStorage.getItem(
    localstorageKeys.unReadNotifications,
  );
  const { isHasOutstandingPayment, subscriptionInfo } =
    useRecoilValue(companyDataState);

  const isReminder = () => {
    const today = moment();
    const nextDate = moment(subscriptionInfo?.nextPaymentDate);
    //get diff between nextpaymentdate and today
    const remainingDays = nextDate.diff(today, 'days');
    return !!(
      remainingDays <= 7 &&
      remainingDays >= 1 &&
      subscriptionInfo.total > 0
    );
  };

  const { data, isLoading, error, isError, isFetched } = useNotificationsQuery({
    page: 1,
    size: 30,
  });

  const [unReadNumber, setUnReadNumber] = useState<number>(0);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const setLocalstorage = (number: number) =>
    localStorage.setItem(
      localstorageKeys.unReadNotifications,
      number.toString(),
    );

  useEffect(() => {
    if (isFetched && data) {
      setUnReadNumber(
        data.notifications.length -
          (oldReadNotifications ? +oldReadNotifications : 0),
      );
    }
  }, [isFetched, oldReadNotifications, data?.notifications.length]);

  const RenderCard: FC<{ item: NotificationItem; isNotRead?: boolean }> = ({
    item,
    isNotRead,
  }) => {
    const { createdAt, enTitle, enText, type } = item;

    return (
      <Box
        sx={[
          {
            width: '100%',
            display: 'flex',
            mb: 1,
            py: 2,
            px: 1,
            borderRadius: '4px',
            ':hover': {
              cursor: 'pointer',
              bgcolor: 'divider',
            },
            textDecoration: 'none',
          },
          isNotRead
            ? {
                bgcolor: 'secondary.light',
              }
            : {},
        ]}
        component={Link}
        to='/settings/billing'
      >
        <Avatar
          sx={{
            width: '58px',
            height: '58px',
            mr: 2,
            p: 1,
          }}
          src={getIcon[type]}
        />
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant='body2' fontWeight={500} color='primary'>
              {enTitle}{' '}
            </Typography>
            <Typography
              variant='body2'
              color='text.secondary'
              fontWeight={400}
              sx={{ mx: 1 }}
            >
              {moment(createdAt).fromNow()}
            </Typography>
          </Box>
          <Typography style={{ fontSize: '13px' }}>{enText}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: 'relative' }}>
          <IconButton size='large' color='inherit' onClick={handleClick}>
            <Badge badgeContent={unReadNumber} color='error'>
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
          {open ? (
            <Box
              sx={{
                position: 'absolute',
                top: isHasOutstandingPayment || isReminder() ? 115 : 55,
                right: 0,
                zIndex: 1500,
                p: '20px',
                bgcolor: 'background.paper',
                width: '680px',
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
                border: 'solid 1px divider',
                maxHeight:
                  isHasOutstandingPayment || isReminder() ? '82vh' : '90vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <Typography variant='h6'>Notifications</Typography>

                <Button
                  color='secondary'
                  style={{ padding: '0px !important' }}
                  onClick={() => {
                    data?.notifications.length &&
                      setLocalstorage(data?.notifications.length);
                    setUnReadNumber(0);
                  }}
                >
                  Mark all as read
                </Button>
              </Stack>

              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  alignItems: 'center',
                  overflow: 'auto',
                }}
              >
                {/*  */}
                {data &&
                  data.notifications.map((item, index) => (
                    <RenderCard
                      key={item.id}
                      item={item}
                      isNotRead={index + 1 <= unReadNumber}
                    />
                  ))}

                {isLoading && <CircularProgress sx={{ my: 2 }} size={30} />}

                {isError && (
                  <Grow in={isError}>
                    <Box mb={3}>
                      <Alert severity='error'>
                        <AlertTitle>Error: fetch notifications</AlertTitle>
                        {error?.error?.message || 'somthing went wrong'}
                      </Alert>
                    </Box>
                  </Grow>
                )}

                <Stack
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <img src={notificationsFlag} alt='notifications-flag' />
                  <Typography style={{ fontSize: '13px' }} textAlign='center'>
                    That's all your notifications
                    <br />
                    from the last 30 days.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </ClickAwayListener>
    </>
  );
};
