export const localstorageKeys = {
  token: 'token',
  refreshToken: 'refreshToken',
  isLivePreview: 'isLivePreview',
  user: 'user',
  companyStatus: 'companyStatus',
  unReadNotifications: 'unReadNotifications',
  companyData: 'companyData',
  building: 'building',
};
