import { putter } from './putter';
import { getter } from './getter';
import { deleter } from './deleter';
import { poster } from './poster';
import { patcher } from './patcher';

export const API = {
  getter,
  putter,
  poster,
  deleter,
  patcher,
};
