import { makeStyles } from '@mui/styles';
export const useReminderAlertStyles = makeStyles(() => ({
  reminder: {
    fontSize: '15px',
    color: '#101928',
    backgroundColor: '#fbd64d',
    justifyContent: 'center',
    borderRadius: '0',
    height: '56px',
    alignItems: 'center',
    '& .MuiAlert-message': {
      marginLeft: 'auto',
    },
  },
  addButton: {
    width: '101px',
    height: '40px',
    margin: '0 0 0 16px',
    padding: '7px 16px',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: '#101928',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&:visited': {
      backgroundColor: '#fff',
    },
  },
}));
