import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '../store/auth';
import { PublicRoute } from '../components/PublicRoute';
import { PrivateRoute } from '../components/PrivateRoute';
import {
  privateRoutes,
  publicRoutes,
  verifiedOnlyRoutes,
  expiredSubscriptionRoutes,
  pendingActivationRoutes,
  activeSubscriptionRoutes,
  publicOpenRoutes,
} from './routes';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { VerifiedRoute } from '../components/VerifiedRoute';
import { ExpiredRoute } from '../components/ExpiredRoute';
import { PendingActivationRoute } from '../components/PendingActivationRoute';
import { ActiveCompanyRoute } from '../components/ActiveCompanyRoute';
import { userState } from '../store/user';
import { PublicOpenRoute } from '../components/PublicOpenRoute';

const Routes = () => {
  const [auth] = useRecoilState(authState);
  const user = useRecoilValue(userState);

  return (
    <Box
      sx={{
        overflow: 'auto',
      }}
    >
      <Router>
        <Suspense
          fallback={
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          }
        >
          <Switch>
            {publicOpenRoutes.map(({ Component, ...rest }, i) => (
              <PublicOpenRoute key={i} {...rest}>
                <Component />
              </PublicOpenRoute>
            ))}

            {publicRoutes.map(({ Component, ...rest }, i) => (
              <PublicRoute key={i} {...rest}>
                <Component />
              </PublicRoute>
            ))}

            {privateRoutes.map(({ Component, ...rest }, i) => (
              <PrivateRoute key={i} {...rest}>
                <Component />
              </PrivateRoute>
            ))}

            {verifiedOnlyRoutes.map(({ Component, roles, ...rest }, i) => (
              <VerifiedRoute
                key={i}
                {...rest}
                canAccess={
                  roles ? (roles.includes(user?.role) ? true : false) : true
                }
              >
                <Component />
              </VerifiedRoute>
            ))}

            {activeSubscriptionRoutes.map(
              ({ Component, roles, ...rest }, i) => (
                <ActiveCompanyRoute
                  key={i}
                  {...rest}
                  canAccess={
                    roles ? (roles.includes(user?.role) ? true : false) : true
                  }
                >
                  <Component />
                </ActiveCompanyRoute>
              ),
            )}

            {expiredSubscriptionRoutes.map(
              ({ Component, roles, ...rest }, i) => (
                <ExpiredRoute
                  key={i}
                  {...rest}
                  canAccess={
                    roles ? (roles.includes(user?.role) ? true : false) : true
                  }
                >
                  <Component />
                </ExpiredRoute>
              ),
            )}

            {pendingActivationRoutes.map(({ Component, roles, ...rest }, i) => (
              <PendingActivationRoute
                key={i}
                {...rest}
                canAccess={
                  roles ? (roles.includes(user?.role) ? true : false) : true
                }
              >
                <Component />
              </PendingActivationRoute>
            ))}

            <Redirect
              from='/'
              to={auth.token ? '/management' : '/auth/login'}
            />
          </Switch>
        </Suspense>
      </Router>
    </Box>
  );
};

export default Routes;
