const locales = {
  forgotYourPassword: 'Forgot your password?',
  itHappens: 'It happens.',
  enterYourEmail:
    "Enter your email and we'll send you instructions on how to reset your password.",
  emailAddress: 'Email address',
  sendInstructions: 'Send Instructions',
  goBackTo: 'Go back to',
  loginPage: 'Login page',
  emailNotRecognized: 'Email not recognized',
  instructionsEmailWasSent: 'Instructions email was sent',
  emailIsRequired: 'Email address is required',
  hangTightLoading: 'Hang tight! We are sending you an Email!',
  emailShouldBeValid: 'Please enter a valid email format',
};

export default locales;
