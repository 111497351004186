import React from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteChildrenProps,
} from 'react-router-dom';

import { Box } from '@mui/material';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '../../store/auth';
import { userState } from '../../store/user';

import TopBar from '../../components/TopBar';
import { companyDataState } from '../../store/company-data';
import PaymentReminder from '../Reminders/PaymentReminder';
import OutstandingPaymentReminder from '../Reminders/OutstandingPaymentReminder';
import { daysUntilServiceEnd } from 'constants/global';

interface IProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
  canAccess?: boolean;
}

export const PendingActivationRoute: React.FC<IProps> = ({
  children,
  canAccess,
  ...rest
}) => {
  const [auth] = useRecoilState(authState);
  const [user] = useRecoilState(userState);
  const {
    subscriptionStatus,
    isHasOutstandingPayment,
    company: { serviceStatus },
  } = useRecoilValue(companyDataState);

  const hasBanner = (): boolean => {
    return !!(
      daysUntilServiceEnd() > 0 &&
      isHasOutstandingPayment &&
      serviceStatus !== 'disabled'
    );
  };

  const isPending = subscriptionStatus === 'pending';

  return (
    <Box>
      <TopBar />
      <PaymentReminder />
      <OutstandingPaymentReminder />
      <Box display='flex' justifyContent='flex-start' overflow='auto'>
        <Box
          style={{
            backgroundColor: '#fafbfc',
            width: '100%',
            height: hasBanner() ? 'calc(100vh - 116px)' : 'calc(100vh - 58px)',
          }}
        >
          <Route
            {...rest}
            render={({ location }) =>
              auth.token &&
              user?.isVerified &&
              user?.companyId &&
              isPending &&
              canAccess ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: auth.token
                      ? user?.isVerified
                        ? serviceStatus === 'disabled'
                          ? '/expired-subscription'
                          : '/auth/register/company'
                        : '/auth/register/verify'
                      : '/auth/login',
                    state: { from: location },
                  }}
                />
              )
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
