import { useMutation, useQuery } from 'react-query';
import { CompanyAPI } from '../../api/company';
import { API } from '../../services/api';
import {
  CompanyBasicInfoResponse,
  CompanyDataResponse,
} from '../../types/company';

export const useGetCompanyMutation = () => {
  return useMutation<CompanyDataResponse, { error: { message: string } }>(() =>
    API.getter(CompanyAPI.companyData),
  );
};

export const useCompanyBasicInfoQuery = () => {
  return useQuery<
    { message: string },
    { error: { message: string } },
    CompanyBasicInfoResponse
  >(
    ['get-company-basic-info'],
    async () => await API.getter(CompanyAPI.companyBasicInfo),
  );
};
