import { makeStyles } from '@mui/styles';
export const useReminderAlertStyles = makeStyles(() => ({
  reminder: {
    fontSize: '15px',
    color: '#fafbfc',
    backgroundColor: '#da2346',
    justifyContent: 'center',
    borderRadius: '0',
    height: '56px',
    alignItems: 'center',
  },
  days: {
    backgroundColor: '#fafbfc',
    color: '#01346f',
    margin: '10px 2px',
    padding: '0 5px 1px',
    borderRadius: '4px',
  },
  addButton: {
    width: '101px',
    height: '40px',
    margin: '0 0 0 16px',
    padding: '7px 16px',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: '#101928',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&:visited': {
      backgroundColor: '#fff',
    },
  },
}));
