const locales = {
  logIntoMyAccount: 'Log Into My Account',
  newToWinFi: 'New to WinFi?',
  createAnAccount: 'Create an account',
  emailAddress: 'Email address',
  password: 'Password',
  logIn: 'Log In',
  forgotYourPassword: 'Forgot your password?',
  errorLoggingIn: 'Error logging in',
  errorSigningUp: 'Error signing up',
  emailIsRequired: 'Email address is required',
  passwordIsRequired: 'Password is required',
  login: 'Log In',
  hangTightLoading: 'Hang tight! We are logging you in!',
  emailShouldBeValid: 'Please enter a valid email format',
  welecome: 'Welcome, {{name}}',
  firsTimeLognSubTitle:
    'You are one step away from creating your account. Just create a passsword and you are all set',
  createPassword: 'Create password',
  createMyAccount: 'Create my account',
  firstTimeLoginFooter: `By clicking the "Sign Up" button, you are creating a WinFi account,and you agree to WinFi’s <TermsLink>Terms</TermsLink> of Use and <PrivacyLink>Privacy Policy</PrivacyLink>.`,
};

export default locales;
