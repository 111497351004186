/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Routes from './routes';
import './i18n';
import { Alert, Box, LinearProgress, Snackbar } from '@mui/material';
import { Notification, notificationsState } from './store/notifications';
import { LoadingState } from './store/global-loading';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { useGetCompanyMutation } from './queries/company/company-data';
import { companyDataState } from './store/company-data';
import { authState } from './store/auth';
import { userState } from './store/user';
import { branchState } from './store/branch';
import { livePreviewState } from './store/live-preview';
import * as amplitude from '@amplitude/analytics-browser';

function App() {
  const user = useRecoilValue(userState);
  const company = useRecoilValue(companyDataState);
  const isLivePreview = useRecoilValue(livePreviewState);
  const setCompanyData = useSetRecoilState(companyDataState);
  const setLoading = useSetRecoilState(LoadingState);
  const setBranch = useSetRecoilState(branchState);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [loading] = useRecoilState(LoadingState);
  const { token } = useRecoilValue(authState);
  const { isVerified, companyId } = useRecoilValue(userState);

  //
  const { data, isSuccess, isError, isLoading, mutate } =
    useGetCompanyMutation();

  //
  const identify = new amplitude.Identify();

  useEffect(() => {
    if (token && isVerified && companyId) {
      mutate();
    }
  }, [token, isVerified, companyId]);

  const trackingUserInit = () => {
    // eslint-disable-next-line eqeqeq
    if (token && user.id && isLivePreview != 'true') {
      amplitude.setUserId(user.id);
      identify.set('email', user.email);
      identify.set('firstName', user.firstName);
      identify.set('lastName', user.lastName);
      identify.set('phoneNumber', user.phoneNumber);
      identify.set('role', user.role);
      identify.set('isVerified', user.isVerified);
      identify.set('company', company.company.name);
      identify.set('companyStatus', company.company.status);
      identify.set('companyServiceStatus', company.company.serviceStatus);
      identify.set('companyType', company.company.type ?? '');
      identify.set('companySubscriptionStatus', company.subscriptionStatus);
      identify.set('companyBranches', company.company.branches.length);

      amplitude.identify(identify);
      // eslint-disable-next-line eqeqeq
    } else if (token && user.id && isLivePreview == 'true') {
      amplitude.setUserId(user.id);
      identify.set('isLivePreview', true);
      identify.set('email', user.email);
      identify.set('firstName', user.firstName);
      identify.set('lastName', user.lastName);
      identify.set('role', user.role);
      identify.set('company', company.company.name);

      amplitude.identify(identify);
    }
  };

  useEffect(() => {
    if (data) {
      setCompanyData(data);
      setBranch([data?.company?.branches?.[0]?.id]);
      trackingUserInit();
    }
  }, [isSuccess, data, data?.subscriptionStatus, data?.company]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handleClose = (index: number, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    let copyOfNotifications = [...notifications];
    copyOfNotifications.splice(index, 1);
    setNotifications(copyOfNotifications);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {loading && <LinearProgress />}
      {!isLoading && <Routes />}
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification: Notification, i: number) => (
          <Snackbar
            key={i}
            open={true}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={(e, r) => handleClose(i, r)}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            <Alert
              onClose={() => handleClose(i)}
              severity={notification.type}
              sx={{ width: '100%' }}
              variant='filled'
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
    </Box>
  );
}

export default App;
