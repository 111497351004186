const locales = {
  office: 'Office',
  cafeOrRestaurant: 'Cafe or restaurant',
  beautySalon: 'Beauty salon',
  hospital: 'Hospital',
  residential: 'Residential',
  gym: 'Gym',
  factory: 'Factory',
  other: 'Other',
  deleteIncompleteSignup: 'Delete incomplete signup',
  araYouSureYouWantToDelete:
    'Are you sure you want to delete <strong>{{lead}}</strong> ?',

  emailShouldBeValid: 'Please enter a valid email format',
  areYouSure:
    'Are you sure you want to <action>{{action}}</action> <strong>{{companyName}}</strong> ?',
  tier1: 'Tier 1 (50m-100m)',
  tier2: 'Tier 2 (100m-200m)',
  tier3: 'Tier 3 (+200m)',
  incomplete: 'Incomplete',
  complete: 'Complete',
  active: 'Active',
  inactive: 'Inactive',
  blocked: 'Blocked',
  starter: 'Starter',
  golden: 'Golden',
  platinum: 'Platinum',
  payAsYouGo: 'Pay as you go',
  fixed: 'Fixed',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  halfYearly: 'Half Yearly',
  yearly: 'Yearly',

  chargeWallet: 'Top up WinFi credit',
  compensation: 'Compensation',
  onlinePaymentRefund: 'Online payment refund',
  deduction: 'Deduction',
  SMSQuota: 'SMS spendings',
  surverysQuota: 'Surveys spendings',
  emailsQuota: 'Emails spendings',
  campaignsQuota: 'Login page Ads spendings',
  paidSubscription: 'Visitors internet consumption',
  custom: 'Custom',
};

export default locales;
