/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useReminderAlertStyles } from './style';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import moment from 'moment';
import { AppBar } from '@mui/material';
import { useTheme } from '@mui/material';
import { companyDataState } from '../../../store/company-data';
import { Reminders } from '../../../store/reminders';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const PaymentReminder: React.FC = () => {
  const classes = useReminderAlertStyles();
  // collapse the reminder
  const [isPaymentReminderOpen, setIsPaymentReminderOpen] =
    React.useState(true);
  const theme = useTheme();
  const { subscriptionInfo, isHasSavedCards } =
    useRecoilValue(companyDataState);
  // sessionstorage => isReminderOpen
  const isReminderOpen = useRecoilValue(Reminders);
  const setIsReminderOpen = useSetRecoilState(Reminders);

  const showPaymentReminder = (info: {
    nextPaymentDate: string;
    total: number;
  }): boolean => {
    const today = moment();
    const nextDate = moment(info?.nextPaymentDate);
    //get diff between nextpaymentdate and today
    const remainingDays = nextDate.diff(today, 'days');
    return !!(remainingDays <= 7 && remainingDays >= 1 && info.total > 0);
  };

  const handleCloseReminder = () => {
    setIsPaymentReminderOpen(false);
    setIsReminderOpen('false');
  };
  return showPaymentReminder(subscriptionInfo) && JSON.parse(isReminderOpen) ? (
    <Collapse in={isPaymentReminderOpen && !isHasSavedCards}>
      <AppBar position='sticky' sx={{ zIndex: theme.zIndex.modal }}>
        <Alert
          className={classes.reminder}
          severity='info'
          icon={false}
          action={
            <IconButton
              style={{ marginLeft: 'auto' }}
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                handleCloseReminder();
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          This is a friendly reminder that your subscription will renew on{' '}
          {moment(subscriptionInfo?.nextPaymentDate)
            .startOf('month')
            .format('D MMMM YYYY')}
          <Button
            size={'medium'}
            className={classes.addButton}
            variant='contained'
          >
            <RouterLink
              to='/settings/billing'
              style={{ textDecoration: 'none', color: '#222' }}
            >
              Add Card
            </RouterLink>
          </Button>
        </Alert>
      </AppBar>
    </Collapse>
  ) : (
    <></>
  );
};

export default PaymentReminder;
