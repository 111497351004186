import { atom } from 'recoil';
import { sessionStorageKeys } from '../services/session-storage-keys';

export const Reminders = atom<string>({
  key: 'reminders', // unique ID
  default: sessionStorage.getItem(sessionStorageKeys.isReminderOpen) || 'false',
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((isReminderOpen) => {
        if (isReminderOpen) {
          sessionStorage.setItem(
            sessionStorageKeys.isReminderOpen,
            isReminderOpen,
          );
        }
      });
    },
  ],
});
